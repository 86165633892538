import React from "react";
import MenuAppBar from "../components/AppBar";
import {
  Typography,
  Button,
  CardMedia,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import GetAppOutlined from "@material-ui/icons/GetAppOutlined";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import DemoVideo from "../constants/Demo video.MOV";

const HelpGuide = () => {
  const FAQsAccordion = () => {
    return (
      <>
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDown />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            How to upload a patient data register on Portal?
          </AccordionSummary>
          <AccordionDetails>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDown />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            How to know if a prediction is assigned for a patient?
          </AccordionSummary>
          <AccordionDetails>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDown />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            Will any data error in the patient records be informed?
          </AccordionSummary>
          <AccordionDetails>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDown />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            How to know which patients are on treatment?
          </AccordionSummary>
          <AccordionDetails>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </AccordionDetails>
        </Accordion>
      </>
    );
  };
  const handleCheckoutDemo = () => {
    window.location.href =
      "https://www.figma.com/proto/KK2eMVjjE0JsOZE1rXBN3Q/LFU-Pilot-Dashboard?page-id=2813%3A804&node-id=2813-805&viewport=1200%2C340%2C0.24&t=HNO1HAUBh5454XYd-1&scaling=scale-down&content-scaling=fixed&starting-point-node-id=2813%3A6457";
  };

  return (
    <div style={{ backgroundColor: "#f3f5fe" }}>
      <MenuAppBar />
      <div
        style={{
          width: "1248px",
          maxWidth: "1248px",
          margin: "auto",
          marginTop: "10vh",
        }}
      >
        <div
          style={{
            backgroundColor: "#50AFF7",
            height: "20vh",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: 0,
            paddingLeft: 150,
            paddingRight: 150,
          }}
        >
          <div>
            <p
              style={{
                fontSize: 24,
                margin: 0,
                color: "#fff",
                fontWeight: 500,
              }}
            >
              Help Guide
            </p>
            <p style={{ fontSize: 16, margin: 0, color: "#fff" }}>
              Everything you need to know about using the portal
            </p>
          </div>
          <Button variant="contained" color="primary" onClick={() => {}}>
            {/* <GetAppOutlined fontSize='small' style={{marginRight: 8}}/> */}
            Checkout Demo
          </Button>
        </div>
        <div
          style={{
            backgroundColor: "#fff",
            paddingTop: "4vh",
            width: "1248px",
            margin: "auto",
          }}
        >
          <div style={{ paddingLeft: 150, paddingRight: 150 }}>
            <Typography style={{ margin: 0, fontWeight: 500 }}>
              TB-Clamp Solution Summary
            </Typography>
            <Typography>
              An AI powered tool to predict the risk of adverse TB Outcomes at
              the onset of treatment initiation
            </Typography>
          </div>
          <div
            style={{
              paddingLeft: 150,
              paddingRight: 150,
              height: 480,
              marginTop: 20,
              marginBottom: 20
            }}
          >
            <iframe
              src="https://drive.google.com/file/d/1uP8VJRlJmI49bE4OY8iLDmmVYO1Djg9Z/preview"
              width="100%"
              height="480px"
              allow="autoplay"
              allowFullScreen
            ></iframe>
    
          </div>
          <div style={{ paddingLeft: 150, paddingRight: 150 }}>
            <FAQsAccordion />
          </div>
          <div style={{ height: 100 }}></div>
        </div>
        <div style={{ height: 100 }}></div>
      </div>
    </div>
  );
};

export default HelpGuide;

import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import { Grid } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { DOWNLOAD_DATA_API } from "../env/index";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  button: {
    // margin: theme.spacing(1),
    color: "white",
    fontSize: "14px",
    height: "40px",
    width: "170px",
    paddingLeft: "12px",
    paddingRight: "12px",
    textTransform: "none",
    backgroundImage: "linear-gradient(180deg, #4752E2 0%, #656EE2 100%)",
    "&.Mui-disabled": {
      color: "white",
      background: "#BDC1C6",
      backgroundColor: "#BDC1C6",
    },
  },
  popover: {
    "& .MuiPaper-root": {
      color: "white",
      backgroundColor: "black",
    },
  },
}));

export default function FileErrors({ fileData, index }) {
  const classes = useStyles();
  // const months = {
  //     1: "January",
  //     2: "February",
  //     3: "March",
  //     4: "April",
  //     5: "May",
  //     6: "June",
  //     7: "July",
  //     8: "August",
  //     9: "September",
  //     10: "October",
  //     11: "November",
  //     12: "December"
  // }

  const fileStartDate = new Date(fileData.start_date_treatment_initiation);
  const fileEndDate = new Date(fileData.end_date_treatment_initiation);

  function toMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber);

    return date.toLocaleString("en-US", {
      month: "long",
    });
  }

  function downloadFile(event, fileData, index) {
    if (index !== 0) return;
    event.preventDefault();
    var urls = [
      fileData?.modified_comorbidity_file,
      fileData?.modified_notification_file,
    ];

    var interval = setInterval(download, 500, urls);

    function download(urls) {
      var url = urls.pop();

      var a = document.createElement("a");
      a.setAttribute("href", url);
      // a.setAttribute('download', '');
      // a.setAttribute('target', '_blank');
      a.click();

      if (urls.length === 0) {
        clearInterval(interval);
      }
    }
  }
  const handleNotificationClick = async (file) => {
    const _body = file.id;

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${
        JSON.parse(window.sessionStorage.getItem("loggedInUser"))?.token
      }`,
    };
    const config = {
      headers: headers,
    };
    await axios
      .post(DOWNLOAD_DATA_API, _body, config)
      .then((response) => {
        const filteredFile = response.data.uploaded_files[0];
        downloadNotificationFile(filteredFile);
      })
      .catch((error) => console.log("error", error));
  };

  const downloadNotificationFile = (fileData) => {
    var url = fileData.modified_notification_file;
    var a = document.createElement("a");
    a.setAttribute("href", url);
    a.click();
  };

  return (
    <>
      <Grid container style={{ justifyContent: "center", marginTop: "10px" }}>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          style={{
            backgroundColor: fileData.error_count > 0 ? "#FCE8E6" : "#F3F5FE",
          }}
        >
          <Grid container direction="column">
            <Grid item xs={12} md={12} lg={12} style={{ marginTop: "15px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {fileData?.status === "SCORED" ? (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        alignItems: "center",
                        color: "#3C4043",
                        marginLeft: "20px",
                        // maxWidth: "50%"
                      }}
                    >
                      {fileStartDate.toLocaleString("en-US", {
                        day: "numeric",
                      })}{" "}
                      {fileStartDate.toLocaleString("en-US", { month: "long" })}{" "}
                      to
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "20px",
                        fontWeight: "600",
                        lineHeight: "32px",
                        letterSpacing: "0.1px",
                        alignItems: "center",
                        color: "#3C4043",
                        marginLeft: "20px",
                        // maxWidth: "50%"
                      }}
                    >
                      {fileEndDate.toLocaleString("en-US", { day: "numeric" })}{" "}
                      {fileEndDate.toLocaleString("en-US", { month: "long" })} '
                      {fileEndDate.toLocaleString("en-US", { year: "2-digit" })}
                    </Typography>
                  </div>
                ) : (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      style={{
                        fontSize: "20px",
                        fontWeight: "600",
                        lineHeight: "32px",
                        letterSpacing: "0.1px",
                        alignItems: "center",
                        color: "#3C4043",
                        marginLeft: "20px",
                        // maxWidth: "50%"
                      }}
                    >
                      {fileData?.status === "FAILED"
                        ? "Failed"
                        : fileData?.status === "SCORING_FAILED"
                        ? "Scoring Failed"
                        : "Processing..."}
                    </Typography>
                  </div>
                )}
              </div>
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "23px",
                  letterSpacing: "0.1px",
                  alignItems: "center",
                  color: "#9AA0A6",
                  marginLeft: "20px",
                }}
              >
                {/* uploaded on {new Date(fileData.uploaded_at).toDateString()} at {new Date(fileData.uploaded_at).toLocaleTimeString([], { hour12: true, hour: '2-digit', minute: '2-digit'  })} */}
                Uploaded On {fileData.uploaded_at?.substring(0, 10)}
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "20px",
                  marginTop: "10px",
                }}
              >
                <img
                  src={require("../constants/xls.svg")}
                  style={{ width: "30px", height: "40px" }}
                  alt="Logo"
                />
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "21px",
                    letterSpacing: "0.1px",
                    alignItems: "center",
                    color: "#9AA0A6",
                    marginLeft: "20px",
                  }}
                >
                  {/* File name: {fileData.file_name} <br/> */}
                  {/* {fileData.original_comorbidity_filename.substring(0, 40) + (fileData.original_comorbidity_filename.length > 40 ? "..." : "")} <br/> */}
                  {fileData.original_notification_file?.substring(1, 40) +
                    (fileData.original_notification_file?.length > 40
                      ? "..."
                      : "")}
                  {/* { fileData.error_count> 0 &&  <> Errors: {fileData.error_count} </>} */}
                </Typography>
                <div>
                  <img
                    src={require("../constants/patient_icon.svg")}
                    style={{
                      marginLeft: "25px",
                      width: "19px",
                      height: "19px",
                    }}
                    alt="Logo"
                  />
                  <br />
                  <img
                    src={require("../constants/error_info.svg")}
                    style={{
                      marginLeft: "25px",
                      width: "19px",
                      height: "16px",
                    }}
                    alt="Logo"
                  />
                </div>

                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "21px",
                    letterSpacing: "0.1px",
                    alignItems: "center",
                    color: "#9AA0A6",
                    marginLeft: "20px",
                  }}
                >
                  Patients: {fileData.total_records} <br />
                  Errors: {fileData.error_count}
                </Typography>
              </div>

              <div
                style={{
                  marginTop: "15px",
                  marginLeft: "20px",
                  marginBottom: "25px",
                }}
              >
                {fileData.status === "SCORED" && fileData.error_count > 0 && (
                  <>
                    <Button
                      className={classes.button}
                      variant="contained"
                      component="label"
                      startIcon={<GetAppOutlinedIcon></GetAppOutlinedIcon>}
                      onClick={() => {
                        handleNotificationClick(fileData);
                      }}
                    >
                      Notification Error
                    </Button>
                  </>
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

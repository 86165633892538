import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { CSVLink } from "react-csv";
import EmptyTable from "../components/EmptyTable";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { getPatientErrors } from "../actions/global";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MenuAppBar from "../components/AppBar";
import MuiAccordion from '@material-ui/core/ExpansionPanel';
import MuiAccordionSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiAccordionDetails from '@material-ui/core/ExpansionPanelDetails';
import PatientDataTable from "../components/PatientDataTable";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux'
import PatientErrorTable from '../components/PatientErrorTable';
import Pagination from '@material-ui/lab/Pagination';

const Accordion = withStyles({
    root: {
      borderTop: "1px solid #BDC1C6",
      borderBottom: "1px solid #BDC1C6",
      boxShadow: "none",
      "&:not(:last-child)": {
        borderBottom: 0
      },
      "&:before": {
        display: "none"
      },
      "&$expanded": {
        marginBottom: "0px",
        marginTop: "0px"
      }
    },
    "&$expanded": {
        marginBottom: "0px",
        marginTop: "0px"
    }
  })(MuiAccordion);

  const AccordionSummary = withStyles({
    root: {
    //   backgroundColor: "yellow",
      // borderBottom: "1px solid yellow",
    //   marginBottom: -1,
      minHeight: 40,
      paddingLeft: "0px",
      paddingRight: "0px",
      "&$expanded": {
        minHeight: 40
      }
    },
    content: {
      "&$expanded": {
        margin: "0 0"
      }
    },
    "&$expanded": {
        marginTop: "0px",
        marginBottom: "0px"
      }
  })(MuiAccordionSummary);

  const AccordionDetails = withStyles((theme) => ({
    root: {
      backgroundColor: "#F8F9FA",
    //   maxHeight: "120px",
      overflow: "auto",
    //   padding: "0px"
    }
  }))(MuiAccordionDetails);

const CssTextField = withStyles({
    root: {
      "& label.Mui-focused": {
        color: "#DADCE0",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#DADCE0",
        borderWidth: "1px",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#DADCE0",
        },
        "&:hover fieldset": {
          borderColor: "#DADCE0",
          borderWidth: "1px",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#DADCE0",
          borderWidth: "1px",
        }
      },
    },
  })(TextField);

const CustomCheckbox = withStyles(theme => ({
    root: {
        color: "white",
        borderRadius: 4,
        border: "1px solid #E8EAED",
        backgroundColor: "white",
        "& .MuiIconButton-label": {
        position: "relative",
        zIndex: 0
        },
        "&$checked": {
            "& .MuiIconButton-label": {
                position: "relative",
                zIndex: 0,
              },
            "& .MuiIconButton-label:after": {
                content: '""',
                height: 15,
                width: 15,
                position: "absolute",
                backgroundColor: "#202124",
                zIndex: -1,
                // borderColor: "transparent"
              }

        },
        "&:not($checked) .MuiIconButton-label:after": {
        content: '""',
        height: 15,
        width: 15,
        position: "absolute",
        backgroundColor: "white",
        zIndex: -1,
        },

    },
    checked: {

    }
}))(Checkbox);

const CustomRadio = withStyles(theme => ({
    root: {
        color: "white",
        '&.Mui-checked': {
            color: "white",
          },
    }
}))(Radio);

const useStyles = makeStyles((theme) => ({
    cardPaper: {
        overflow: "auto",
        boxShadow: "2px 2px 2px 2px lightgrey",
        width: "100%",
        height: "130px",
        border: "1px solid #DADCE0",
        borderRadius: "20px",
        fontSize: "22px"
    },
    downloadButton: {
        margin: theme.spacing(1),
        marginTop: "20px",
        marginRight: "30px",
        color: "white",
        fontSize: "14px",
        height: "48px",
        paddingLeft: "30px",
        paddingRight: "30px",
        backgroundImage: "linear-gradient(180deg, #4752E2 0%, #656EE2 100%)",

    },
    searchButton: {
        color: "white",
        fontSize: "14px",
        height: "48px",
        marginTop: "35px",
        paddingLeft: "30px",
        paddingRight: "30px",
        backgroundImage: "linear-gradient(180deg, #4752E2 0%, #656EE2 100%)",

    },
    riskCheckBox: {
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "24px",
        letterSpacing: "0.1px",
        alignItems: "center",
        marginLeft: "5px",
        color: "white"
    },
    listCheckBox: {
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "16px",
        letterSpacing: "0.1px",
        alignItems: "center",
        marginLeft: "10px",
        color: "#4A4A4A"
    }
}))

export default function PatientErrors () {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [tableLoader, setTableLoader] = React.useState(false)
    const patientErrors = useSelector(state => state.globalActionsReducer.patientErrors)
    // const patientData = useSelector(state => state.globalActionsReducer.patientData)
    const riskFiltersInfo = useSelector(state => state.globalActionsReducer.riskFiltersInfo)
    const TUList = useSelector(state => state.globalActionsReducer.TUList)
    const PHIList = useSelector(state => state.globalActionsReducer.PHIList)

    const [startDate, setStartDate] = React.useState("")
    const [endDate, setEndDate] = React.useState("")
    const [startDateError, setStartDateError] = React.useState("")
    const [endDateError, setEndDateError] = React.useState("")
    const [reportData, setReportData] = React.useState([]);
    const [expanded, setExpanded] = React.useState("");
    const [page, setPage] = React.useState(1);

    const [initialRisk, setInitialRisk] = React.useState("")
    const [finalRisk, setFinalRisk] = React.useState("")

    const [ErrorCheckedList, setErrorCheckedList] = React.useState({})
    const [WarningCheckedList, setWarningCheckedList] = React.useState({})
    const [TUCheckedList, setTUCheckedList] = React.useState({})
    const [PHICheckedList, setPHICheckedList] = React.useState({})
    const [AllErrorsChecked, setAllErrorsChecked] = React.useState("")
    const [AllWarningsChecked, setAllWarningsChecked] = React.useState("")
    const [AllTUChecked, setAllTUChecked] = React.useState("")
    const [AllPHIChecked, setAllPHIChecked] = React.useState("")

    const initialFilter = {
        initial_risk: "",
        final_risk: "",
        error: [],
        warning: [],
        tu: [],
        phi: []

    }
    const errorList = ['BasisOfDiagnosis_LabSerialNo', 'BasisOfDiagnosis_TestName', 'CurrentFacilityDistrict', 'CurrentFacilityPHI', 'CurrentFacilityPHIType', 'CurrentFacilityPHI_ID', 'CurrentFacilityState', 'CurrentFacilityTBU', 'DateOf_TB_Treatment_Initiation', 'DiabetesStatus', 'DiagnosingFacilityDistrict', 'DiagnosingFacilityPHI', 'DiagnosingFacilityPHIType', 'DiagnosingFacilityPHI_ID', 'DiagnosingFacilityState', 'DiagnosingFacilityTBU', 'EPSite', 'EnrollmentFacilityDistrict', 'EnrollmentFacilityPHI', 'EnrollmentFacilityPHIType', 'EnrollmentFacilityPHI_ID', 'EnrollmentFacilityState', 'EnrollmentFacilityTBU', 'EpisodeID', 'Gender', 'Microbiologically_Confirmed', 'PatientId', 'Residential_District', 'Residential_TU', 'Residential_State', 'SiteOfDisease', 'TypeOfCase', 'Weight', 'IngenID', 'PatientName', 'Age', 'PrimaryPhone', 'HIV_Status', 'Height', 'Current_facility_in_Medical_College', 'Diagnosed_by_Medical_College', 'Enrolled_by_Medical_College', 'Valid_result_for_Rif_available_via_DRT', 'Rif_Resistance_Detected', 'Rif_resistance_not_detected', 'IsCurrent_Spectrum_HWC', 'IsDiagnosing_Spectrum_HWC']
    const warningList = ["Age", "basisOfDiagnosis_FinalInterpretation"]
    const [filters, setFilters] = React.useState({ ...initialFilter })

    const csvLink = useRef()

    useEffect(() => {
        document.body.style.backgroundColor = "#F3F5FE";
      }, [])

    useEffect(() => {

        TUList.forEach((tu) => {
            TUCheckedList[tu] = TUCheckedList?.[tu] ? true : false
        })
        setTUCheckedList({...TUCheckedList})

        PHIList.forEach((phi) => {
            PHICheckedList[phi] = PHICheckedList?.[phi] ? true: false
        })
        setPHICheckedList({...PHICheckedList})

        errorList.forEach((error) => {
            ErrorCheckedList[error] = ErrorCheckedList?.[error] ? true : false
        })
        setErrorCheckedList({...ErrorCheckedList})

        warningList.forEach((warning) => {
            WarningCheckedList[warning] = WarningCheckedList?.[warning] ? true : false
        })
        setWarningCheckedList({...WarningCheckedList})

    }, [filters])


    useEffect(() => {
        handleSearchClick();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (reportData.length > 0) { csvLink.current.link.click() }
    }, [reportData])

    useEffect(() => {
        if (JSON.stringify(initialFilter) === JSON.stringify(filters)) {
            setInitialRisk("")
            setFinalRisk("")
            setStartDate("")
            setEndDate("")
            setStartDateError("")
            setEndDateError("")
            TUList.forEach((tu) => {
                TUCheckedList[tu] = false;
            })
            setTUCheckedList({ ...TUCheckedList })
            PHIList.forEach((phi) => {
                PHICheckedList[phi] = false;
            })
            setPHICheckedList({ ...PHICheckedList })
        }
    }, [filters])

    useEffect(() => {
    
        setTableLoader(true)
    
        dispatch(getPatientErrors(startDate, endDate, filters, page)).then(() => {
            setTableLoader(false)
        });
        
    }, [page])
    
    const handleFilterChange = (key, value) => {
        if (filters.hasOwnProperty(key)) { filters[key] = value; }
        setFilters({ ...filters });
    };
    
    function toggleLocation (location, locationType) {
        if (locationType === "tu") {
            console.log("entered tu")
            TUCheckedList[location] = !TUCheckedList[location]
            setTUCheckedList({ ...TUCheckedList })
        } else {
            PHICheckedList[location] = !PHICheckedList[location]
            setPHICheckedList({ ...PHICheckedList })
        }

        if (filters[locationType].indexOf(location) === -1) { filters[locationType].push(location) } else { filters[locationType].splice(filters[locationType].indexOf(location), 1) }
        setFilters({ ...filters })
    }

    function toggleAllOption(type) {
        
        filters[type] = []
        if (type === 'error') {

            errorList.map((error)=>{
                if(!AllErrorsChecked){
                    filters[type].push(error)
                }
                ErrorCheckedList[error] = !AllErrorsChecked
            })
            setErrorCheckedList({ ...ErrorCheckedList })
            setAllErrorsChecked(!AllErrorsChecked)
        
        }
        else if(type === 'warning') {

            warningList.map((warning)=>{

                if(!AllWarningsChecked){
                    filters[type].push(warning)
                }
                WarningCheckedList[warning] = !AllWarningsChecked
            })
            setWarningCheckedList({ ...WarningCheckedList })
            setAllWarningsChecked(!AllWarningsChecked)

        }
        else if(type === 'tu') {

            
            TUList.map((tu)=>{

                if(!AllTUChecked){
                    filters[type].push(tu)
                }
                TUCheckedList[tu] = !AllTUChecked
            })
            setTUCheckedList({...TUCheckedList})
            setAllTUChecked(!AllTUChecked)

        }
        else if(type === 'phi') {
            PHIList.map((phi)=>{

                if(!AllPHIChecked){
                    filters[type].push(phi)
                }
                PHICheckedList[phi] = !AllPHIChecked
            })
            setPHICheckedList({...PHICheckedList})
            setAllPHIChecked(!AllPHIChecked)
        }            
    }

    function toggleError (error, type) {

        if (type === 'error') {
            ErrorCheckedList[error] = !ErrorCheckedList[error]
            setErrorCheckedList({ ...ErrorCheckedList })
        }else{
            WarningCheckedList[error] = !WarningCheckedList[error]
            setWarningCheckedList({ ...WarningCheckedList })

        }


        if (filters[type].indexOf(error) === -1) { filters[type].push(error) } else { filters[type].splice(filters[type].indexOf(error), 1) }
        setFilters({ ...filters })
    }

    function handleSearchClick () {
        setTableLoader(true)
        dispatch(getPatientErrors(startDate, endDate, filters)).then(() => {
            setTableLoader(false)
        });
    }

    function handleDateChange (event) {
        if (event?.target?.id === "start_date") {
            if (endDate === "" || event.target.value <= endDate) {
                setStartDate(event.target.value)
                setStartDateError("")
            } else {
                setStartDateError("To date cannot be more than From date")
                setEndDate("")
            }
        } else if (event?.target?.id === "end_date") {
            if (startDate === "" || event.target.value >= startDate) {
                setEndDate(event.target.value)
                setEndDateError("")
            } else {
                setEndDateError("From date cannot be less than To date")
                setEndDate("")
            }
        }
    }

    function clearFilters() {
        setFilters({ ...initialFilter })
        setAllErrorsChecked("")
        setAllWarningsChecked("")
        setAllTUChecked("")
        setAllPHIChecked("")

        errorList.forEach((error) => {
            ErrorCheckedList[error] = false;
        })
        warningList.forEach((warning) => {
            WarningCheckedList[warning] = false;
        })


    }

    async function handleDownload () {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Token ${JSON.parse(window.sessionStorage.getItem("loggedInUser"))?.token}`
        }
        const response = await fetch('https://tb-clamp-api.wadhwaniai.org/portal/download-patient-errors', {
            method: 'POST',
            body:JSON.stringify(filters),
            headers: headers,
        });
        console.log(response)
        if (response.ok) {
            const csvBlob = await response.blob();
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(csvBlob);
            link.download = 'patienterror_data.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.error('Failed to fetch CSV from the backend');
        }

    }
    const handleAccordionChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
      };

    return (
    <>
    <MenuAppBar />
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
    <Button onClick={handleDownload} className={classes.downloadButton} startIcon={<GetAppOutlinedIcon></GetAppOutlinedIcon>}>Download</Button>
    </div>
        <Grid container spacing={1} justify="center">
            <Grid item xs={12} md={2} lg={2} >
                <Grid container justify="flex-end">
                    <Grid item xs={12} md={11} lg={11} style={{ marginTop: "20px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Typography
                            display="inline"
                            style={{
                                    fontSize: "20px",
                                    fontWeight: "600",
                                    lineHeight: "32px",
                                    letterSpacing: "0.1px",
                                    alignItems: "center",
                                    color: "#3C4043",
                                    marginTop: "2px",
                                    marginBottom: "2px"
                                    }}>
                                    Filters
                            </Typography>
                            {
                                (JSON.stringify(initialFilter) !== JSON.stringify(filters) || startDate !== "" || endDate !== "") &&
                                <Button style={{ backgroundColor: 'transparent', textTransform: "none" }}
                                    onClick={() => 
                                            clearFilters()
                                        }>
                                <Typography
                                display="inline"
                                style={{
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                        letterSpacing: "0.1px",
                                        alignItems: "center",
                                        color: "#EE6002",
                                        }}>
                                        Clear All
                                </Typography>
                                </Button>

                            }
                        </div>
                    </Grid>
                    <Grid item xs={12} md={11} lg={11} style={{ marginTop: "10px", borderRadius: "4px", backgroundColor: "#50AFF7" }}>
                        <Grid container justify="center">
                                <Grid item xs={12} md={10} lg={10} style={{ textAlign: "left", marginTop: "20px" }}>
                                    <Typography
                                    style={{
                                            fontSize: "18px",
                                            fontWeight: "600",
                                            lineHeight: "32px",
                                            letterSpacing: "0.1px",
                                            alignItems: "center",
                                            color: "white",
                                            }}>
                                            Treatment Initiation date
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={10} lg={10} style={{ textAlign: "left", marginBottom: "30px" }}>
                                    <div style={{ display: "flex", flexDirection: "column", marginTop: "17px" }}>
                                        <Typography style={{
                                            fontSize: "12px",
                                            fontWeight: "400",
                                            lineHeight: "16px",
                                            letterSpacing: "0.1px",
                                            alignItems: "center",
                                            color: "white",
                                            }}>
                                            From
                                        </Typography>

                                        <CssTextField
                                                id="start_date"
                                                type="date"
                                                size="small"
                                                variant="outlined"
                                                value={startDate}
                                                onChange={handleDateChange}
                                                // label={"Start Date"}
                                                // className={classes.textField}
                                                style={{ maxWidth: "204px", background: "white" }}
                                                InputLabelProps={{
                                                shrink: true,
                                                }}
                                                inputProps={{
                                                max: new Date().toISOString().split("T")[0],
                                                style: { color: "#202124" }
                                                }}
                                                helperText={startDateError !== "" ? startDateError : ""}
                                                error={startDateError !== ""}
                                            />
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", marginTop: "25px" }}>
                                    <Typography style={{
                                            fontSize: "12px",
                                            fontWeight: "400",
                                            lineHeight: "16px",
                                            letterSpacing: "0.1px",
                                            alignItems: "center",
                                            color: "white",
                                            }}>
                                            To
                                    </Typography>
                                    <CssTextField
                                            id="end_date"
                                            type="date"
                                            size="small"
                                            variant="outlined"
                                            value={endDate}
                                            onChange={handleDateChange}
                                            // label={"End Date"}
                                            // className={classes.textField}
                                            style={{ maxWidth: "204px", background: "white" }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            inputProps={{
                                            max: new Date().toISOString().split("T")[0],
                                            style: { color: "#202124" }
                                            }}
                                            helperText={endDateError !== "" ? endDateError : ""}
                                            error={endDateError !== ""}
                                        />
                                    </div>
                                </Grid>

                        </Grid>

                    </Grid>

                    <Grid item xs={12} md={11} lg={11} >
                        <Accordion
                            square
                            elevation={0}
                            expanded={expanded === "panel1"}
                            onChange={() => setExpanded((prev) => { return prev === "panel1" ? "" : "panel1" })}
                        >
                        <AccordionSummary id="panel1d-header" style={{ backgroundColor: "#F3F5FE", maxHeight: "45px" }} >
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                            <Typography style={{
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                lineHeight: "26px",
                                                letterSpacing: "0.1px",
                                                alignItems: "center",
                                                color: "#4A4A4A",
                                                }}>
                                                Errors
                            </Typography>
                            {expanded !== "panel1" ? <KeyboardArrowDownIcon style={{ color: "#4A4A4A", fontSize: "30px" }}/> : <KeyboardArrowUpIcon style={{ color: "#4A4A4A", fontSize: "30px" }}/>}
                            </div>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: "0px", backgroundColor: "#F3F5FE" }}>
                        <Grid container style={{ borderRadius: "4px", maring: "5px" }}>
                            <Grid item xs={12} md={10} lg={10} style={{ textAlign: "left", maxHeight: "200px" }}>
                                
                                <div style={{ marginTop: "5px", marginBottom: "20px" }}>
                                <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                    <CustomCheckbox style={{ color: "white", padding: "0px" }}
                                    onChange={() => toggleAllOption("error")} checked={!!AllErrorsChecked}/>
                                    <Typography className={classes.listCheckBox}>
                                            {"Select All"}
                                    </Typography>
                                    </div>

                                    {
                                        errorList.map(
                                            (error) => (
                                                <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                                <CustomCheckbox id={error} style={{ color: "white", padding: "0px" }}
                                                onChange={(event) => toggleError(event.target.id, "error")} checked={!!ErrorCheckedList[error]}/>
                                                <Typography className={classes.listCheckBox}>
                                                        {error}
                                                </Typography>
                                                </div>
                                            )
                                        )
                                    }
                                    </div>
                            </Grid>
                        </Grid>
                        </AccordionDetails>
                        </Accordion>
                    </Grid>
                    {/* <Grid item xs={12} md={11} lg={11} >
                        <Accordion
                            square
                            elevation={0}
                            expanded={expanded === "panel2"}
                            onChange={() => setExpanded((prev) => { return prev === "panel2" ? "" : "panel2" })}
                        >
                        <AccordionSummary id="panel2d-header" style={{ backgroundColor: "#F3F5FE", maxHeight: "45px" }} >
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                            <Typography style={{
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                lineHeight: "26px",
                                                letterSpacing: "0.1px",
                                                alignItems: "center",
                                                color: "#4A4A4A",
                                                }}>
                                                Warnings
                            </Typography>
                            {expanded !== "panel2" ? <KeyboardArrowDownIcon style={{ color: "#4A4A4A", fontSize: "30px" }}/> : <KeyboardArrowUpIcon style={{ color: "#4A4A4A", fontSize: "30px" }}/>}
                            </div>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: "0px", backgroundColor: "#F3F5FE" }}>
                        <Grid container style={{ borderRadius: "4px", maring: "5px" }}>
                            <Grid item xs={12} md={10} lg={10} style={{ textAlign: "left", maxHeight: "200px" }}>
                                    <div style={{ marginTop: "5px", marginBottom: "20px" }}>
                                  
                                    <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                    <CustomCheckbox style={{ color: "white", padding: "0px" }}
                                    onChange={() => toggleAllOption("warning")} checked={!!AllWarningsChecked}/>
                                    <Typography className={classes.listCheckBox}>
                                            {"Select All"}
                                    </Typography>
                                    </div>

                                    {
                                        warningList.map(
                                            (warning) => (
                                                <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                                <CustomCheckbox id={warning} style={{ color: "white", padding: "0px" }}
                                                onChange={(event) => toggleError(event.target.id, "warning")} checked={!!WarningCheckedList[warning]}/>
                                                <Typography className={classes.listCheckBox}>
                                                        {warning}
                                                </Typography>
                                                </div>
                                            )
                                        )
                                    }
                                    </div>
                            </Grid>
                        </Grid>
                        </AccordionDetails>
                        </Accordion>
                    </Grid> */}
                    <Grid item xs={12} md={11} lg={11} >
                        <Accordion
                            square
                            elevation={0}
                            expanded={expanded === "panel3"}
                            onChange={() => setExpanded((prev) => { return prev === "panel3" ? "" : "panel3" })}
                        >
                        <AccordionSummary id="panel3d-header" style={{ backgroundColor: "#F3F5FE", maxHeight: "45px" }} >
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                            <Typography style={{
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                lineHeight: "26px",
                                                letterSpacing: "0.1px",
                                                alignItems: "center",
                                                color: "#4A4A4A",
                                                }}>
                                                TU
                            </Typography>
                            {expanded !== "panel3" ? <KeyboardArrowDownIcon style={{ color: "#4A4A4A", fontSize: "30px" }}/> : <KeyboardArrowUpIcon style={{ color: "#4A4A4A", fontSize: "30px" }}/>}
                            </div>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: "0px", backgroundColor: "#F3F5FE" }}>
                        <Grid container style={{ borderRadius: "4px", maring: "5px" }}>
                            <Grid item xs={12} md={10} lg={10} style={{ textAlign: "left", maxHeight: "200px" }}>
                                    <div style={{ marginTop: "5px", marginBottom: "20px" }}>
                                    
                                    <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                    <CustomCheckbox style={{ color: "white", padding: "0px" }}
                                    onChange={(event) => toggleAllOption( "tu")} checked={!!AllTUChecked}/>
                                    <Typography className={classes.listCheckBox}>
                                            {"Select All"}
                                    </Typography>
                                    </div>

                                    {
                                        TUList.map(
                                            (tu) => (
                                                <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                                <CustomCheckbox id={tu} style={{ color: "white", padding: "0px" }}
                                                onChange={(event) => toggleLocation(event.target.id, "tu")} checked={!!TUCheckedList[tu]}/>
                                                <Typography className={classes.listCheckBox}>
                                                        {tu}
                                                </Typography>
                                                </div>
                                            )
                                        )
                                    }
                                    </div>
                            </Grid>
                        </Grid>
                        </AccordionDetails>
                        </Accordion>
                    </Grid>

                    <Grid item xs={12} md={11} lg={11} >
                        <Accordion
                            square
                            elevation={0}
                            expanded={expanded === "panel4"}
                            onChange={() => setExpanded((prev) => { return prev === "panel4" ? "" : "panel4" })}
                        >
                        <AccordionSummary id="panel4d-header" style={{ backgroundColor: "#F3F5FE", maxHeight: "45px" }} >
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                            <Typography style={{
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                lineHeight: "26px",
                                                letterSpacing: "0.1px",
                                                alignItems: "center",
                                                color: "#4A4A4A",
                                                }}>
                                                PHI
                            </Typography>
                            {expanded !== "panel4" ? <KeyboardArrowDownIcon style={{ color: "#4A4A4A", fontSize: "30px" }}/> : <KeyboardArrowUpIcon style={{ color: "#4A4A4A", fontSize: "30px" }}/>}
                            </div>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: "0px", backgroundColor: "#F3F5FE" }}>
                        <Grid container style={{ borderRadius: "4px", maring: "5px" }}>
                            <Grid item xs={12} md={10} lg={10} style={{ textAlign: "left", maxHeight: "200px" }}>
                                    <div style={{ marginTop: "5px", marginBottom: "20px" }}>

                                    <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                    <CustomCheckbox style={{ color: "white", padding: "0px" }}
                                    onChange={() => toggleAllOption("phi")} checked={AllPHIChecked}/>
                                    <Typography className={classes.listCheckBox}>
                                            {"Select All"}
                                    </Typography>
                                    </div>

                                    {
                                        PHIList.map(
                                            (phi) => (
                                                <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                                <CustomCheckbox id={phi} style={{ color: "white", padding: "0px" }}
                                                onChange={(event) => toggleLocation(event.target.id, "phi")} checked={!!PHICheckedList[phi]}/>
                                                <Typography className={classes.listCheckBox}>
                                                        {phi}
                                                </Typography>
                                                </div>
                                            )
                                        )
                                    }
                                    </div>
                            </Grid>
                        </Grid>
                        </AccordionDetails>
                        </Accordion>
                    </Grid>

                    <Grid item xs={12} md={11} lg={11}>
                        <Grid container justify="flex-start">
                            <Grid item >
                                <Button onClick={handleSearchClick} className={classes.searchButton} >Apply Filter</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={10} lg={10} style={{ marginBottom: "20px" }}>
                { tableLoader === true ? <EmptyTable /> : <PatientErrorTable patientErrors={patientErrors}/> }
            </Grid>
            <Pagination color="primary" style={{ marginTop: '25px', display: 'flex', justifyContent: 'center' }} 
                count={Math.ceil((riskFiltersInfo?.error_count) / 100)}             
                page={page} 
                onChange ={(e,value)=>{
                    setPage(value)
                    console.log(page)
            }}/>

        </Grid>
    </>
    )
}

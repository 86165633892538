import qs from "query-string"
import { createBrowserHistory } from 'history';

const history = createBrowserHistory()

export const fetchApi = (url, method = "GET", body = {}, headers = new Headers()) => {
    headers.append('Authorization', 'Token ' + JSON.parse(window.sessionStorage.getItem('loggedInUser'))?.token)
    const obj = { method, headers, body }
    if (method === 'DELETE') {
        url = qs.stringifyUrl({ url: url, query: body });
        delete obj.body
    }

    if (method === 'GET') {
        delete obj.body
    }

    // console.log("request body", url, obj)
    return fetch(url, obj).then(res => {
        // console.log("this is reponse ", res.json())
        return res.json()
    }).catch(e => {
        history.push('/login')
        return e
    })
}

export const fetchUpload = (url, method = "GET", body = {}, headers = new Headers()) => {
    headers.append('Authorization', 'Token ' + JSON.parse(window.sessionStorage.getItem('loggedInUser'))?.token)
    const obj = { method, headers, body }

    return fetch(url, obj)
    .then(res => {
        console.log("this is reponse ", res)
        if(res.status === 417){
            return {"Error": "Invalid schema, check the schema of the uploaded file"}
        }
        else if(res.status === 415){
            return {"Error": "Unsupported file format, Given file is not an excel file"}
        } else if(res.status === 500) {
            return {"Error": `Server Error: ${res.statusText}`}
        }
        return res.json()
    }).catch(e => {
        // console.log(e)
        return {"Error": "There was a problem uploading the file, please try again"}
    })
}

export const loginFetchApi = (url, method = "GET", body = {}, headers = new Headers()) => {
    const obj = { method, headers, body }
    return fetch(url, obj).then(res => {
        // console.log("this is reponse ", res.json())
        return res.json()
    }).catch(e => {
        history.push('/login')
    })
}

// export default fetchApi

import { Redirect, Route } from 'react-router-dom'

import React from 'react'
import { useSelector } from 'react-redux'

// PrivateRoute component is the blueprint for all private routes in the application. If the user is logged in, go on and display the component in question; otherwise, redirect the user to sign-in page.

const PrivateRoute = ({ component: Component, ...rest }) => {
  const loggedInUser = useSelector(
    (state) => state.loginActionsReducer.loggedInUser
  );

  return (
    <div>
    <Route
      {...rest}
      render={props =>
        loggedInUser !== null && loggedInUser !== undefined && loggedInUser.token !== undefined ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
    </div>
  )
}

export default PrivateRoute

import AppBar from "@material-ui/core/AppBar";
import { login } from "../actions/login";
import logo from "../constants/new_logo.png"
import React, { useState } from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button, Grid, IconButton, InputAdornment, TextField, Typography } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom"
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";


const useStyles = makeStyles((theme) => ({
  button: {
      // margin: theme.spacing(1),
      marginTop: "30px",
      color: "white",
      fontSize: "14px",
      width: "102px",
      height: "40px",
      paddingLeft: "30px",
      paddingRight: "30px",
      textTransform: "none",
      backgroundImage: "linear-gradient(180deg, #4752E2 0%, #656EE2 100%)",
  },
  cssTextField: {
      "& label.Mui-focused": {
        color: "#DADCE0",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#DADCE0",
        borderWidth: "1px",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#50AFF7",
        },
        "&:hover fieldset": {
          borderColor: "#50AFF7",
          borderWidth: "1px",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#50AFF7",
          borderWidth: "1px",
        },
        [theme.breakpoints.up('lg')]: {
          width: "320px"
        },
        [theme.breakpoints.down('md')]: {
          width: "220px"
        }
      },
  }
}))

const Login = () => {
    const theme = useTheme()
    const lg = useMediaQuery(theme.breakpoints.up('lg'))
    

    const classes = useStyles() 
    const history = useHistory()
    const dispatch = useDispatch()
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const errorText = useSelector((state) => state.loginActionsReducer?.loginError)
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    function handleLogin () {
      setSubmitted(true)
      if (username && password) {
          dispatch(login(username, password))
          .then(() => {
            if(JSON.parse(window.sessionStorage.getItem('loggedInUser')) !== "")
              history.push('/risk_analysis')
          })
      }
    };
  
    

    return (
    <>
    <Grid container style={{height: window.innerHeight}}>
        <Grid item xs={4} md={4} lg={4}>
          <AppBar 
            position="relative"
            elevation={4}
            style={{
              background: "white",
              height: "80px",
              display: "flex",
              justifyContent: "center"}} 
            >
              <Toolbar>
                <Grid item>
                  <img src={logo} alt="Logo" style={{ paddingLeft: "20px" }} />
                </Grid>
              </Toolbar>
          </AppBar>
          <Grid container justify="center" alignItems="center" style={{height: "100%", backgroundColor: "white"}}>
              <Grid item xs={8} md={8} lg={8} style={{display: "flex", justifyContent: "center"}}>
                <Grid container style={{marginTop: "100px"}}>
                  <Grid item xs={12} md={12} lg={12} style={{display: "flex", justifyContent: "center"}}>
                    <Typography style={{
                            fontSize: "40px",
                            fontWeight: "600",
                            lineHeight: "32px",
                            letterSpacing: "0.1px",
                            alignItems: "center",
                            color: "#202124",
                            }}>
                            Login
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12} style={{display: "flex", justifyContent: "center", marginTop: "30px"}}>
                    <TextField
                      className={classes.cssTextField}
                      variant="outlined"
                      // fullWidth
                      margin="normal"
                      id="username"
                      label="Enter your username"
                      name="username"
                      type="username"
                      // autoFocus
                      error={submitted && !username}
                      helperText={<Typography component={'span'} style={{ marginLeft: "-14px", fontSize: "10px" }}>{submitted && !username ? 'Username is required' : ' '}</Typography>}
                      onChange={e => setUserName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12} style={{display: "flex", justifyContent: "center"}}>
                    <TextField
                      className={classes.cssTextField}
                      variant="outlined"
                      margin="normal"
                      // fullWidth
                      id="password"
                      label="Enter your password"
                      name="password"
                      autoComplete="password"
                      error={(submitted && !password) || (errorText !== "" && errorText !== undefined)}
                      helperText={<Typography component={'span'} style={{ marginLeft: "-14px", fontSize: "10px" }}>{submitted && !password ? 'Password is required' : errorText}</Typography>}
                      onChange={e => setPassword(e.target.value)}
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12} style={{display: "flex", justifyContent: "center"}}>
                    <Button className={classes.button} onClick={handleLogin} variant="contained" component="label" >
                      Log in
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Typography style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0.1px",
                      alignItems: "center",
                      color: "black",
                      opacity: 0.5,
                      // marginTop: "300px"
                      }}>
                      Contact Wadhwani AI If You Face Challenges Logging In
                </Typography> */}
          </Grid>
        </Grid>
        <Grid item xs={8} md={8} lg={8} style={{backgroundColor: "#4954E2", height: "100%"}}>
          <Grid container justify="center" alignItems="center" style={{height: "100%"}}>
            <Grid item xs={10} md={10} lg={10} style={{display: "flex", justifyContent: "center"}}>
              <div>
                <img src={require("../constants/hero_image.svg")}  alt="Login" />
                {/* <Typography style={{
                      fontSize: "15px",
                      fontWeight: "600",
                      lineHeight: "48px",
                      letterSpacing: "0.1px",
                      alignItems: "center",
                      color: "white",
                      opacity: 0.5,
                      marginTop: "45px"
                      }}>
                      LOSS TO FOLLOW UP
                </Typography> */}
                <Typography style={{
                    fontSize: "33px",
                    fontWeight: "400",
                    lineHeight: "48px",
                    letterSpacing: "0.1px",
                    alignItems: "center",
                    color: "white",
                    width: "530px",
                    }}>
                    Proactively identify tuberculosis patients at high risk of being "lost to follow-up and mortality"
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
        
    </Grid>
    </> 
     
    );
  }

export default Login;

import { Chip, Button, Box } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import React, { useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';

export default function PatientDataTable ({ patientData }) {
    const [initialAnchorEl, setInitialAnchorEl] = React.useState()
    const [finalAnchorEl, setFinalAnchorEl] = React.useState()
    const [initialPopover, setInitialPopover] = React.useState(false)
    const [finalPopover, setFinalPopover] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const riskFiltersInfo = useSelector(state => state.globalActionsReducer.riskFiltersInfo)

    const getDateFromTimestamp = (timestamp) => {
        if (typeof timestamp === 'string') {
            const arr = timestamp.split('-')

            const year = arr[0]
            const month = arr[1]
            const day = arr[2]

            return `${day}/${month}/${year}`
        }
        const date = new Date((timestamp * 1000))

        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
    }
    const open = Boolean(anchorEl);

    const handlePopoverOpen = (event) => {
        // console.log("Hello world")
        setAnchorEl(event.currentTarget);
      };

      const handlePopoverClose = () => {
        // setAnchorEl(null);
        console.log("Removed", open);
        setTimeout(() => {
            setAnchorEl(null);
        }, 3500)
      };
      const history = useHistory();

    return (
        <div>

<Popover

sx={{ pointerEvents: 'none' }} open={open} anchorEl={anchorEl} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} transformOrigin={{ vertical: 'top', horizontal: 'left' }} >

<Box style={{ display: 'flex', flexDirection: 'column', boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.12)", borderRadius: '0.5rem', padding: "11px", textAlign: 'center' }} onMouseLeave={() => { setAnchorEl(null) }}>
    <Typography style={{ fontSize: "1rem", color: '#EE6002', fontWeight: 700 }}>
            Patient information is missing
        {/* <div style={{ fontSize: "18px", fontWeight: 700 , textAlign:'center'}}> */}
        {/* </div> */}
        <Typography style={{ color: '#7B7B7B', fontSize: '14px', marginTop: '0.5rem', paddingLeft: '10px', paddingRight: '10px' }}>
            All the information required to access the patient's risk <br/> score was not available. Update the information and <br/> re-upload the data to get more accurate assessments. <br/>
        </Typography>
    </Typography>

    <Button
    onClick={() => { history.push('/upload') }}
    style={{
        alignSelf: 'center',
        color: "#FFF",
        background: "linear-gradient(180deg, #4752E2 0%, #656EE2 100%)",
        textTransform: "capitalize",
        border: "none",
        padding: "0.5rem 1rem",
        borderRadius: "0.5rem",
        fontWeight: 600,
        marginTop: "0.75rem",
        padding: "0.5rem 1.5rem",

    }}
    >
    Upload Now
    </Button>
</Box>
</Popover>

        <TableContainer style={{ marginTop: "20px", maxHeight: "700px", overflowY: 'overlay' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>

                <Table stickyHeader aria-label="customized table" style={{ marginLeft: "35px", marginRight: "30px" }}>
                    <TableHead>
                        <TableRow>
                            <TableCell align='center' style={{ backgroundColor: "#50AFF7", width: "7%", textAlign: "left", color: "white", fontWeight: "700" }}>PatientID</TableCell>
                            <TableCell align='center' style={{ backgroundColor: "#50AFF7", width: "8%", textAlign: "left", color: "white", fontWeight: "700" }}>Episode ID</TableCell>
                            <TableCell align='center' style={{ backgroundColor: "#50AFF7", width: "10%", textAlign: "left", color: "white", fontWeight: "700" }}>Patient Name</TableCell>
                            <TableCell align='center' style={{ backgroundColor: "#50AFF7", width: "12%", textAlign: "left", color: "white", fontWeight: "700" }}>Treatment Initiation</TableCell>
                            <TableCell align='center' style={{ backgroundColor: "#50AFF7", width: "10%", textAlign: "left", color: "white", fontWeight: "700" }}>Current District</TableCell>
                            <TableCell align='center' style={{ backgroundColor: "#50AFF7", width: "10%", textAlign: "left", color: "white", fontWeight: "700" }}>Current TU</TableCell>
                            <TableCell align='center' style={{ backgroundColor: "#50AFF7", width: "10%", textAlign: "left", color: "white", fontWeight: "700" }}>Current PHI</TableCell>
                            <TableCell align='center' style={{ backgroundColor: "#50AFF7", width: "10%", textAlign: "left", color: "white", fontWeight: "700" }}>Contact Details</TableCell>
                            <TableCell align='center' style={{ backgroundColor: "#50AFF7", width: "10%", textAlign: "left", color: "white", fontWeight: "700" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                Risk Status

                            </div>
                            </TableCell>
                            <TableCell align='center' style={{ backgroundColor: "#50AFF7", width: "5%", textAlign: "left", color: "white", fontWeight: "700" }}>Treatment Outcome</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ backgroundColor: "white" }}>
                        {patientData.sort((a, b) => { return (b.DateOf_TB_Treatment_Initiation - a.DateOf_TB_Treatment_Initiation) }) && patientData.map((sample) => {
                            return (
                            <>
                            <TableRow key={sample?.episode_id} hover>
                                
                                <TableCell align='center' style={{ textAlign: "left", color: "#4A4A4A" }}>{sample?.PatientId || sample?.IngenID}</TableCell>
                                <TableCell align='center' style={{ textAlign: "left", color: "#4A4A4A" }}>{sample?.episode_id}</TableCell>
                                <TableCell align='center' style={{ textAlign: "left", color: "#4A4A4A" }}>{sample?.PatientName}</TableCell>
                                <TableCell align='center' style={{ textAlign: "left", color: "#4A4A4A" }}>{sample ? getDateFromTimestamp(sample.DateOf_TB_Treatment_Initiation) : ""}</TableCell>
                                <TableCell align='center' style={{ textAlign: "left", color: "#4A4A4A" }}>{sample?.currentFacilityDistrict}</TableCell>
                                <TableCell align='center' style={{ textAlign: "left", color: "#4A4A4A" }}>{sample?.currentFacilityTBU}</TableCell>
                                <TableCell align='center' style={{ textAlign: "left", color: "#4A4A4A" }}>{sample?.currentFacilityPHI}</TableCell>
                                <TableCell align='center' style={{ textAlign: "left", color: "#4A4A4A" }}>
                                    <div>
                                    <Typography style={{ color: "#4A4A4A", fontSize: "14px" }} >
                                    {sample?.PrimaryPhone?.substring(0, 30)}
                                    </Typography>
                                    </div>
                                </TableCell>
                                <TableCell align='center' style={{ textAlign: "left" }}>
                                {
                                    sample?.risk_class === 'H'
                                    ? <Chip label={"High"} style={{ backgroundColor: "#EE675C", color: "white", width: "60px", height: "25px", fontWeight: "700" }} />
                                    : sample?.risk_class === 'L'
                                    ? <Chip label={"Low"} style={{ color: "#3C4043", width: "60px", height: "25px" }} variant="outlined"/>
                                    : <Chip label={"Null"} style={{ color: "#292929", width: "60px", height: "25px" }} variant="outlined"/>
                                }
                                </TableCell>
                                <TableCell align='center' style={{ textAlign: "left", color: "#4A4A4A" }}>{sample?.Treatment_Outcome ?sample?.Treatment_Outcome == 'None' ? "Not Assigned": sample?.Treatment_Outcome: "Not Assigned" }</TableCell>

                            </TableRow>
                            </>
                        )
                        })}
                    </TableBody>
                </Table>
            </div>
        </TableContainer>
        </div>

    )
}



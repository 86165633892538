import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { CSVLink } from "react-csv";
import EmptyTable from "../components/EmptyTable";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import { getPatientData } from "../actions/global";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuAppBar from "../components/AppBar";
import MuiAccordion from "@material-ui/core/ExpansionPanel";
import MuiAccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiAccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import PatientDataTable from "../components/PatientDataTable";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import { fetchApi } from "../actions/fetchApi";
import { FETCH_API } from "../env";

const Accordion = withStyles({
  root: {
    borderTop: "1px solid #BDC1C6",
    borderBottom: "1px solid #BDC1C6",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      marginBottom: "0px",
      marginTop: "0px",
    },
  },
  "&$expanded": {
    marginBottom: "0px",
    marginTop: "0px",
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    //   backgroundColor: "yellow",
    // borderBottom: "1px solid yellow",
    //   marginBottom: -1,
    minHeight: 40,
    paddingLeft: "0px",
    paddingRight: "0px",
    "&$expanded": {
      minHeight: 40,
    },
  },
  content: {
    "&$expanded": {
      margin: "0 0",
    },
  },
  "&$expanded": {
    marginTop: "0px",
    marginBottom: "0px",
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    backgroundColor: "#F8F9FA",
    //   maxHeight: "120px",
    overflow: "auto",
    //   padding: "0px"
  },
}))(MuiAccordionDetails);

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#DADCE0",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#DADCE0",
      borderWidth: "1px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#DADCE0",
      },
      "&:hover fieldset": {
        borderColor: "#DADCE0",
        borderWidth: "1px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#DADCE0",
        borderWidth: "1px",
      },
    },
  },
})(TextField);

const CustomCheckbox = withStyles((theme) => ({
  root: {
    color: "white",
    borderRadius: 4,
    border: "1px solid #E8EAED",
    backgroundColor: "white",
    "& .MuiIconButton-label": {
      position: "relative",
      zIndex: 0,
    },
    "&$checked": {
      "& .MuiIconButton-label": {
        position: "relative",
        zIndex: 0,
      },
      "& .MuiIconButton-label:after": {
        content: '""',
        height: 15,
        width: 15,
        position: "absolute",
        backgroundColor: "#202124",
        zIndex: -1,
        // borderColor: "transparent"
      },
    },
    "&:not($checked) .MuiIconButton-label:after": {
      content: '""',
      height: 15,
      width: 15,
      position: "absolute",
      backgroundColor: "white",
      zIndex: -1,
    },
  },
  checked: {},
}))(Checkbox);

const CustomRadio = withStyles((theme) => ({
  root: {
    color: "white",
    "&.Mui-checked": {
      color: "white",
    },
  },
}))(Radio);

const useStyles = makeStyles((theme) => ({
  cardPaper: {
    overflow: "auto",
    boxShadow: "2px 2px 2px 2px lightgrey",
    width: "100%",
    height: "130px",
    border: "1px solid #DADCE0",
    borderRadius: "20px",
    fontSize: "22px",
  },
  downloadButton: {
    margin: theme.spacing(1),
    marginTop: "20px",
    marginRight: "30px",
    color: "white",
    fontSize: "14px",
    height: "48px",
    paddingLeft: "30px",
    paddingRight: "30px",
    backgroundImage: "linear-gradient(180deg, #4752E2 0%, #656EE2 100%)",
  },
  searchButton: {
    color: "white",
    fontSize: "14px",
    height: "48px",
    marginTop: "35px",
    paddingLeft: "30px",
    paddingRight: "30px",
    backgroundImage: "linear-gradient(180deg, #4752E2 0%, #656EE2 100%)",
  },
  riskCheckBox: {
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0.1px",
    alignItems: "center",
    marginLeft: "5px",
    color: "white",
  },
  listCheckBox: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "16px",
    letterSpacing: "0.1px",
    alignItems: "center",
    marginLeft: "10px",
    color: "#4A4A4A",
  },
}));

export default function RiskAnalysis() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [tableLoader, setTableLoader] = React.useState(false);
  const patientData = useSelector(
    (state) => state.globalActionsReducer.patientData
  );
  const allPatientData = useSelector(
    (state) => state.globalActionsReducer.allpatientData
  );
  const riskFiltersInfo = useSelector(
    (state) => state.globalActionsReducer.riskFiltersInfo
  );
  const TUList = useSelector((state) => state.globalActionsReducer.TUList);
  const PHIList = useSelector((state) => state.globalActionsReducer.PHIList);
  const DistrictList = useSelector(
    (state) => state.globalActionsReducer.DistrictList
  );

  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [startDateError, setStartDateError] = React.useState("");
  const [endDateError, setEndDateError] = React.useState("");
  const [reportHeaders, setReportHeaders] = React.useState([]);
  const [expanded, setExpanded] = React.useState("");

  const [initialRisk, setInitialRisk] = React.useState("");
  const [finalRisk, setFinalRisk] = React.useState("");

  const [AllTUChecked, setAllTUChecked] = React.useState("");
  const [AllPHIChecked, setAllPHIChecked] = React.useState("");
  const [AllDistrictChecked, setAllDistrictChecked] = React.useState("");
  const [TUCheckedList, setTUCheckedList] = React.useState({});
  const [PHICheckedList, setPHICheckedList] = React.useState({});
  const [DistrictCheckedList, setDistrictCheckedList] = React.useState({});
  const [page, setPage] = React.useState(1);
  const [records, setRecords] = React.useState([]);

  const initialFilter = {
    initial_risk: "",
    final_risk: "",
    tu: [],
    phi: [],
    district: [],
    eligibility: "",
  };

  const [filters, setFilters] = React.useState({ ...initialFilter });

  const csvLink = useRef();

  useEffect(() => {
    document.body.style.backgroundColor = "#F3F5FE";
  }, []);

  useEffect(() => {
    setTableLoader(true);

    dispatch(getPatientData(startDate, endDate, filters, page)).then(() => {
      setTableLoader(false);
    });
  }, [page]);

  //   causing infinite render
  useEffect(() => {
    TUList.forEach((tu) => {
      TUCheckedList[tu] = !!TUCheckedList?.[tu];
    });
    setTUCheckedList({ ...TUCheckedList });
    PHIList.forEach((phi) => {
      PHICheckedList[phi] = !!PHICheckedList?.[phi];
    });
    setPHICheckedList({ ...PHICheckedList });

    PHIList.forEach((district) => {
      DistrictCheckedList[district] = !!DistrictCheckedList?.[district];
    });
    setDistrictCheckedList({ ...DistrictCheckedList });
  }, []);

  useEffect(() => {
    // handleSearchClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (JSON.stringify(initialFilter) === JSON.stringify(filters)) {
      setInitialRisk("");
      setFinalRisk("");
      setStartDate("");
      setEndDate("");
      setStartDateError("");
      setEndDateError("");
      TUList.forEach((tu) => {
        TUCheckedList[tu] = false;
      });
      DistrictList.forEach((dist) => {
        DistrictCheckedList[dist] = false;
      });
      PHIList.forEach((phi) => {
        PHICheckedList[phi] = false;
      });
      setTUCheckedList({ ...TUCheckedList });
      setPHICheckedList({ ...PHICheckedList });
      setDistrictCheckedList({ ...DistrictCheckedList });
    }
  }, [filters]);
  const handleFilterChange = (key, value) => {
    if (filters.hasOwnProperty(key)) {
      filters[key] = value;
    }
    setFilters({ ...filters });
  };

  function toggleAllOption(type) {
    filters[type] = [];

    if (type === "tu") {
      TUList.map((tu) => {
        if (!AllTUChecked) {
          filters[type].push(tu);
        }

        TUCheckedList[tu] = !AllTUChecked;
        setTUCheckedList({ ...TUCheckedList });
      });

      setAllTUChecked(!AllTUChecked);
    } else {
      PHIList.map((phi) => {
        if (!AllPHIChecked) {
          filters[type].push(phi);
        }

        PHICheckedList[phi] = !AllPHIChecked;
        setPHICheckedList({ ...PHICheckedList });
      });
      setAllPHIChecked(!AllPHIChecked);
    }
  }
  function toggleLocation(location, locationType) {
    if (locationType === "tu") {
      TUCheckedList[location] = !TUCheckedList[location];
      setTUCheckedList({ ...TUCheckedList });
    } else if (locationType === "district") {
      DistrictCheckedList[location] = !DistrictCheckedList[location];
      setDistrictCheckedList({ ...DistrictCheckedList });
    } else {
      PHICheckedList[location] = !PHICheckedList[location];
      setPHICheckedList({ ...PHICheckedList });
    }

    if (filters[locationType].indexOf(location) === -1) {
      filters[locationType].push(location);
    } else {
      filters[locationType].splice(filters[locationType].indexOf(location), 1);
    }
    setFilters({ ...filters });
  }

  function handleSearchClick() {
    setTableLoader(true);
    dispatch(getPatientData(startDate, endDate, filters, page)).then(() => {
      setTableLoader(false);
    });
  }

  function handleDateChange(event) {
    if (event?.target?.id === "start_date") {
      if (endDate === "" || event.target.value <= endDate) {
        setStartDate(event.target.value);
        setStartDateError("");
      } else {
        setStartDateError("To date cannot be more than From date");
        setEndDate("");
      }
    } else if (event?.target?.id === "end_date") {
      if (startDate === "" || event.target.value >= startDate) {
        setEndDate(event.target.value);
        setEndDateError("");
      } else {
        setEndDateError("From date cannot be less than To date");
        setEndDate("");
      }
    }
  }

  function clearFilters() {
    setFilters({ ...initialFilter });
    setAllTUChecked("");
    setAllPHIChecked("");
    setAllDistrictChecked("");
  }

  async function handleDownload() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${
        JSON.parse(window.sessionStorage.getItem("loggedInUser"))?.token
      }`,
    };

    const apiUrl =
      "https://tb-clamp-api.wadhwaniai.org/portal/download-patient-records";

    const response = await fetch(apiUrl, {
      method: "POST",
      body: JSON.stringify(filters),
      headers: headers,
    });
    console.log(response);
    if (response.ok) {
      const csvBlob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(csvBlob);
      link.download = "patient_data.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Failed to fetch CSV from the backend");
    }
  }

  const handleAccordionChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <MenuAppBar />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          onClick={handleDownload}
          className={classes.downloadButton}
          startIcon={<GetAppOutlinedIcon></GetAppOutlinedIcon>}
        >
          Download
        </Button>
      </div>
      <Grid container spacing={1} justify="center">
        <Grid item xs={12} md={2} lg={2}>
          <Grid container justify="flex-end">
            <Grid item xs={12} md={11} lg={11} style={{ marginTop: "20px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  display="inline"
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    lineHeight: "32px",
                    letterSpacing: "0.1px",
                    alignItems: "center",
                    color: "#3C4043",
                    marginTop: "2px",
                    marginBottom: "2px",
                  }}
                >
                  Filters
                </Typography>
                {(JSON.stringify(initialFilter) !== JSON.stringify(filters) ||
                  startDate !== "" ||
                  endDate !== "") && (
                  <Button
                    style={{
                      backgroundColor: "transparent",
                      textTransform: "none",
                    }}
                    onClick={() => clearFilters()}
                  >
                    <Typography
                      display="inline"
                      style={{
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "24px",
                        letterSpacing: "0.1px",
                        alignItems: "center",
                        color: "#EE6002",
                      }}
                    >
                      Clear All
                    </Typography>
                  </Button>
                )}
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={11}
              lg={11}
              style={{
                marginTop: "10px",
                borderRadius: "4px",
                backgroundColor: "#50AFF7",
              }}
            >
              <Grid container justify="center">
                <Grid
                  item
                  xs={12}
                  md={10}
                  lg={10}
                  style={{ textAlign: "left", marginTop: "20px" }}
                >
                  <Typography
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      lineHeight: "32px",
                      letterSpacing: "0.1px",
                      alignItems: "center",
                      color: "white",
                    }}
                  >
                    Treatment Initiation Date
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={10}
                  lg={10}
                  style={{ textAlign: "left", marginBottom: "30px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "17px",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "16px",
                        letterSpacing: "0.1px",
                        alignItems: "center",
                        color: "white",
                      }}
                    >
                      From
                    </Typography>

                    <CssTextField
                      id="start_date"
                      type="date"
                      size="small"
                      variant="outlined"
                      value={startDate}
                      onChange={handleDateChange}
                      // label={"Start Date"}
                      // className={classes.textField}
                      style={{ maxWidth: "204px", background: "white" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        max: new Date().toISOString().split("T")[0],
                        style: { color: "#202124" },
                      }}
                      helperText={startDateError !== "" ? startDateError : ""}
                      error={startDateError !== ""}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "25px",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "16px",
                        letterSpacing: "0.1px",
                        alignItems: "center",
                        color: "white",
                      }}
                    >
                      To
                    </Typography>
                    <CssTextField
                      id="end_date"
                      type="date"
                      size="small"
                      variant="outlined"
                      value={endDate}
                      onChange={handleDateChange}
                      // label={"End Date"}
                      // className={classes.textField}
                      style={{ maxWidth: "204px", background: "white" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        max: new Date().toISOString().split("T")[0],
                        style: { color: "#202124" },
                      }}
                      helperText={endDateError !== "" ? endDateError : ""}
                      error={endDateError !== ""}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={11} lg={11} style={{ marginTop: "20px" }}>
              <Accordion
                square
                elevation={0}
                expanded={expanded === "panel1"}
                onChange={() =>
                  setExpanded((prev) => {
                    return prev === "panel1" ? "" : "panel1";
                  })
                }
              >
                <AccordionSummary
                  id="panel1d-header"
                  style={{ backgroundColor: "#F3F5FE", maxHeight: "45px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "26px",
                        letterSpacing: "0.1px",
                        alignItems: "center",
                        color: "#4A4A4A",
                      }}
                    >
                      Risk Status
                    </Typography>
                    {expanded !== "panel1" ? (
                      <KeyboardArrowDownIcon
                        style={{ color: "#4A4A4A", fontSize: "30px" }}
                      />
                    ) : (
                      <KeyboardArrowUpIcon
                        style={{ color: "#4A4A4A", fontSize: "30px" }}
                      />
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails style={{ padding: "0px" }}>
                  <Grid
                    container
                    justify="center"
                    style={{
                      borderRadius: "4px",
                      backgroundColor: "#50AFF7",
                      maring: "5px",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={10}
                      lg={10}
                      style={{ textAlign: "left", paddingTop: "10px" }}
                    >
                      <Typography
                        style={{
                          fontSize: "18px",
                          fontWeight: "600",
                          lineHeight: "32px",
                          letterSpacing: "0.1px",
                          alignItems: "center",
                          color: "white",
                        }}
                      >
                        Risk Status
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={10}
                      lg={10}
                      style={{ textAlign: "left" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          marginTop: "5px",
                          marginBottom: "20px",
                        }}
                      >
                        <FormControl>
                          {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel> */}
                          <RadioGroup
                            id="initial_risk"
                            name="initial risk"
                            value={initialRisk}
                            onChange={(event) => {
                              setInitialRisk(event.target.value);
                              handleFilterChange(
                                "initial_risk",
                                event.target.value
                              );
                            }}
                          >
                            <FormControlLabel
                              value="Low"
                              control={<CustomRadio />}
                              label={
                                <Typography className={classes.riskCheckBox}>
                                  Low({riskFiltersInfo?.count_low_initial})
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              value="High"
                              control={<CustomRadio />}
                              label={
                                <Typography className={classes.riskCheckBox}>
                                  High({riskFiltersInfo?.count_high_initial})
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              value="YTG"
                              control={<CustomRadio />}
                              label={
                                <Typography className={classes.riskCheckBox}>
                                  Null(
                                  {riskFiltersInfo?.count_not_generated_initial}
                                  )
                                </Typography>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12} md={11} lg={11}>
              <Accordion
                square
                elevation={0}
                expanded={expanded === "panel2"}
                onChange={() =>
                  setExpanded((prev) => {
                    return prev === "panel2" ? "" : "panel2";
                  })
                }
              >
                <AccordionSummary
                  id="panel2d-header"
                  style={{ backgroundColor: "#F3F5FE", maxHeight: "45px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "26px",
                        letterSpacing: "0.1px",
                        alignItems: "center",
                        color: "#4A4A4A",
                      }}
                    >
                      TU
                    </Typography>
                    {expanded !== "panel2" ? (
                      <KeyboardArrowDownIcon
                        style={{ color: "#4A4A4A", fontSize: "30px" }}
                      />
                    ) : (
                      <KeyboardArrowUpIcon
                        style={{ color: "#4A4A4A", fontSize: "30px" }}
                      />
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  style={{ padding: "0px", backgroundColor: "#F3F5FE" }}
                >
                  <Grid
                    container
                    style={{ borderRadius: "4px", maring: "5px" }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={10}
                      lg={10}
                      style={{ textAlign: "left", maxHeight: "200px" }}
                    >
                      <div style={{ marginTop: "5px", marginBottom: "20px" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <CustomCheckbox
                            style={{ color: "white", padding: "0px" }}
                            onChange={(event) => toggleAllOption("tu")}
                            checked={AllTUChecked}
                          />
                          <Typography className={classes.listCheckBox}>
                            {"Select All"}
                          </Typography>
                        </div>

                        {TUList.map((tu) => (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "10px",
                            }}
                          >
                            <CustomCheckbox
                              id={tu}
                              style={{ color: "white", padding: "0px" }}
                              onChange={(event) =>
                                toggleLocation(event.target.id, "tu")
                              }
                              checked={!!TUCheckedList[tu]}
                            />
                            <Typography className={classes.listCheckBox}>
                              {tu}
                            </Typography>
                          </div>
                        ))}
                      </div>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12} md={11} lg={11}>
              <Accordion
                square
                elevation={0}
                expanded={expanded === "panel3"}
                onChange={() =>
                  setExpanded((prev) => {
                    return prev === "panel3" ? "" : "panel3";
                  })
                }
              >
                <AccordionSummary
                  id="panel3d-header"
                  style={{ backgroundColor: "#F3F5FE", maxHeight: "45px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "26px",
                        letterSpacing: "0.1px",
                        alignItems: "center",
                        color: "#4A4A4A",
                      }}
                    >
                      PHI
                    </Typography>
                    {expanded !== "panel3" ? (
                      <KeyboardArrowDownIcon
                        style={{ color: "#4A4A4A", fontSize: "30px" }}
                      />
                    ) : (
                      <KeyboardArrowUpIcon
                        style={{ color: "#4A4A4A", fontSize: "30px" }}
                      />
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  style={{ padding: "0px", backgroundColor: "#F3F5FE" }}
                >
                  <Grid
                    container
                    style={{ borderRadius: "4px", maring: "5px" }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={10}
                      lg={10}
                      style={{ textAlign: "left", maxHeight: "200px" }}
                    >
                      <div style={{ marginTop: "5px", marginBottom: "20px" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <CustomCheckbox
                            style={{ color: "white", padding: "0px" }}
                            onChange={() => toggleAllOption("phi")}
                            checked={AllPHIChecked}
                          />
                          <Typography className={classes.listCheckBox}>
                            {"Select All"}
                          </Typography>
                        </div>

                        {PHIList.map((phi) => (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "10px",
                            }}
                          >
                            <CustomCheckbox
                              id={phi}
                              style={{ color: "white", padding: "0px" }}
                              onChange={(event) =>
                                toggleLocation(event.target.id, "phi")
                              }
                              checked={!!PHICheckedList[phi]}
                            />
                            <Typography className={classes.listCheckBox}>
                              {phi}
                            </Typography>
                          </div>
                        ))}
                      </div>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12} md={11} lg={11}>
              <Accordion
                square
                elevation={0}
                expanded={expanded === "panel4"}
                onChange={() =>
                  setExpanded((prev) => {
                    return prev === "panel4" ? "" : "panel4";
                  })
                }
              >
                <AccordionSummary
                  id="panel4d-header"
                  style={{ backgroundColor: "#F3F5FE", maxHeight: "45px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "26px",
                        letterSpacing: "0.1px",
                        alignItems: "center",
                        color: "#4A4A4A",
                      }}
                    >
                      District
                    </Typography>
                    {expanded !== "panel4" ? (
                      <KeyboardArrowDownIcon
                        style={{ color: "#4A4A4A", fontSize: "30px" }}
                      />
                    ) : (
                      <KeyboardArrowUpIcon
                        style={{ color: "#4A4A4A", fontSize: "30px" }}
                      />
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  style={{ padding: "0px", backgroundColor: "#F3F5FE" }}
                >
                  <Grid
                    container
                    style={{ borderRadius: "4px", maring: "5px" }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={10}
                      lg={10}
                      style={{ textAlign: "left", maxHeight: "200px" }}
                    >
                      <div style={{ marginTop: "5px", marginBottom: "20px" }}>
                        {DistrictList?.map((dt) => (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "10px",
                            }}
                          >
                            <CustomCheckbox
                              id={dt}
                              style={{ color: "white", padding: "0px" }}
                              onChange={(event) =>
                                toggleLocation(event.target.id, "district")
                              }
                              checked={!!DistrictCheckedList[dt]}
                            />
                            <Typography className={classes.listCheckBox}>
                              {dt}
                            </Typography>
                          </div>
                        ))}
                      </div>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12} md={11} lg={11}>
              <Accordion
                square
                elevation={0}
                expanded={expanded === "panel5"}
                onChange={() =>
                  setExpanded((prev) => {
                    return prev === "panel5" ? "" : "panel5";
                  })
                }
              >
                <AccordionSummary
                  id="panel5d-header"
                  style={{ backgroundColor: "#F3F5FE", maxHeight: "45px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "26px",
                        letterSpacing: "0.1px",
                        alignItems: "center",
                        color: "#4A4A4A",
                      }}
                    >
                      On Treatment Patients
                    </Typography>
                    {expanded !== "panel5" ? (
                      <KeyboardArrowDownIcon
                        style={{ color: "#4A4A4A", fontSize: "30px" }}
                      />
                    ) : (
                      <KeyboardArrowUpIcon
                        style={{ color: "#4A4A4A", fontSize: "30px" }}
                      />
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails style={{ padding: "0px" }}>
                  <Grid
                    container
                    justify="center"
                    style={{
                      borderRadius: "4px",
                      backgroundColor: "#50AFF7",
                      maring: "5px",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={10}
                      lg={10}
                      style={{ textAlign: "left", paddingTop: "10px" }}
                    >
                      {/* <Typography
                        style={{
                          fontSize: "18px",
                          fontWeight: "600",
                          lineHeight: "32px",
                          letterSpacing: "0.1px",
                          alignItems: "center",
                          color: "white",
                        }}
                      >
                        On Treatment Patients
                      </Typography> */}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={10}
                      lg={10}
                      style={{ textAlign: "left" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          marginTop: "5px",
                          marginBottom: "20px",
                        }}
                      >
                        <FormControl>
                          <RadioGroup
                            id="eligibility"
                            name="eligibility"
                            value={initialRisk}
                            onChange={(event) => {
                              setInitialRisk(event.target.value);
                              handleFilterChange(
                                "eligibility",
                                event.target.value
                              );
                            }}
                          >
                            <FormControlLabel
                              value="eligible_patients"
                              control={<CustomRadio />}
                              label={
                                <Typography className={classes.riskCheckBox}>
                                  On Treatment Patients
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              value="all_patients"
                              control={<CustomRadio />}
                              label={
                                <Typography className={classes.riskCheckBox}>
                                  All Patients
                                </Typography>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12} md={11} lg={11}>
              <Grid container justify="flex-start">
                <Grid item>
                  <Button
                    onClick={handleSearchClick}
                    className={classes.searchButton}
                  >
                    Apply Filter
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={10} lg={10} style={{ marginBottom: "20px" }}>
          {tableLoader === true ? (
            <EmptyTable />
          ) : (
            <>
              <PatientDataTable patientData={patientData} />
            </>
          )}
        </Grid>
        <Pagination
          color="primary"
          style={{
            marginTop: "25px",
            display: "flex",
            justifyContent: "center",
          }}
          count={Math.ceil(riskFiltersInfo?.total_patients_count / 100)}
          page={page}
          onChange={(e, value) => {
            setPage(value);
            // console.log(page)
          }}
        />
      </Grid>
    </>
  );
}

import React from "react";
import { useLocation } from "react-router-dom"

function useQuery () {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const DemoRedirect = () => {
  const query = useQuery();

  const userDetails = query.get("userDetails");
  if (userDetails) {
    window.sessionStorage.setItem('loggedInUser', userDetails);
    window.location.href = '/upload'
  } else {
    window.location.href = '/login'
  }

  return (
    <>
    </>
  );
}

export default DemoRedirect;

import AppBar from "@material-ui/core/AppBar";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Button from "@material-ui/core/Button";
import { Divider } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Grid from "@material-ui/core/Grid";
// import logo from "../constants/new_logo.png"
import logo from "../constants/portal_logo.svg"
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import React, { useEffect } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom"
import { logOut } from "../actions/login";

const useStyles = makeStyles((theme) => ({
  highlight: {
      // margin: theme.spacing(1),
      textDecoration: "underline",
      fontWeight: "600",
  },
  heading: {
    fontWeight: "400"
  },
  divider: {
    height: "24px",
    background: "black",
    alignSelf: "center"
},

}))

export default function MenuAppBar ({ userInfo }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const username = useSelector((state) => state.loginActionsReducer.loggedInUser)?.name
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const page = window.location.pathname;

  function handleLogout () {
    dispatch(logOut());
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHelpGuideClick = () => {
    history.push("/help-guide")
  }
  return (
    <React.Fragment>
      <AppBar
        position="relative"
        elevation={4}
        style={{
          background: "white",
          height: "80px",
          display: "flex",
          justifyContent: "center",
        }}
      >

        <Toolbar>
          <Grid item xs={2} md={2} lg={2}>
          <img src={logo} alt="Logo" style={{ paddingLeft: "20px", cursor: "pointer" }} onClick={() => { history.push('upload') }}/>
          </Grid>
          <Grid item xs={8} md={8} lg={8}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              justifyContent: "center",
              flex: 1,
            }}
          >
          <Button style={{ backgroundColor: 'transparent', textTransform: "none", marginLeft: "40px" }}
          onClick={() => { history.push('/risk_analysis') }}>
            <img src={require("../constants/patient_risk_icon.svg")} style={{ width: "30px", height: "25px" }} alt="Logo" />
            <Typography
            // className={headerOption === 1 ? classes.highlight : classes.heading}
            className={page === "/risk_analysis" ? classes.highlight : classes.heading}
              style={{
                color: "#3C4043",
                fontSize: "14px",
                // fontWeight: "500",
                lineHeight: "18px",
                letterSpacing: "0.1px",
                marginLeft: "8px"
                // padding: 15,
              }}
            >
              Adverse Outcomes Risk
            </Typography>
          </Button>
          <Button style={{ backgroundColor: 'transparent', textTransform: "none", marginLeft: "20px" }}
          onClick={() => { history.push('/patient_data_errors') }}>
            <img src={require("../constants/patient_risk_icon.svg")} style={{ width: "30px", height: "25px" }} alt="Logo" />
            <Typography
            // className={headerOption === 1 ? classes.highlight : classes.heading}
            className={page === "/patient_data_errors" ? classes.highlight : classes.heading}
              style={{
                color: "#3C4043",
                fontSize: "14px",
                // fontWeight: "500",
                lineHeight: "18px",
                letterSpacing: "0.1px",
                marginLeft: "8px"
                // padding: 15,
              }}
            >
              Patient Data Errors
            </Typography>

          </Button>
          <Button style={{ backgroundColor: 'transparent', textTransform: "none", marginLeft: "20px" }}
          onClick={() => { history.push('/upload') }}>
          <img src={require("../constants/patient_upload_icon.png")} style={{ width: "30px", height: "25px" }} alt="Logo" />
          <Typography
          // className={headerOption === 0 ? classes.highlight : classes.heading}
          className={page === "/upload" ? classes.highlight : classes.heading}
              style={{
                color: "#3C4043",
                fontSize: "14px",
                // fontWeight: "500",
                lineHeight: "18px",
                letterSpacing: "0.1px",
                marginLeft: "15px"
                // padding: 15,
              }}
            >
              Upload Patient Information
            </Typography>
          </Button>

          </Grid>
          <Grid item xs={2} md={2} lg={2}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "right",
              flex: 1,
            }}
            
          >
          <Typography
              style={{
                color: "#3C4043",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
                letterSpacing: "0.1px",
                marginRight: "15px",
                cursor:"pointer"
                // padding: 15,
              }}
              onClick={handleHelpGuideClick}
              
            >
              Help Guide
            </Typography>
            <Divider orientation="vertical" flexItem classes={{ root: classes.divider }}/>
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              style={{
                // padding: 15,
                textTransform: "none"
              }}
            >
            <Typography
              style={{
                color: "#3C4043",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
                letterSpacing: "0.1px",
                marginLeft: "15px"
                // padding: 15,
              }}
            >
              {username}
            </Typography>
              {/* <PersonIcon /> */}
              <ArrowDropDownIcon />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              style={{ marginRight: "20px" }}
            >
              <MenuItem onClick={handleLogout} >
                <ExitToAppIcon />
                Logout
              </MenuItem>
            </Menu>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

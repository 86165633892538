
import { Button, makeStyles, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import { Grid } from '@material-ui/core';
import React from 'react';
import PublishIcon from '@material-ui/icons/Publish';

const useStyles = makeStyles((theme) => ({
    region: {
        position: "relative",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: 'column',
    },
    drag: {
        position: "absolute",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        backgroundColor: "white",
        opacity: ".5"
    },
    uploadButton: {
        margin: theme.spacing(1),
        color: "white",
        fontSize: "14px",
        height: "48px",
        paddingLeft: "30px",
        paddingRight: "30px",
        backgroundImage: "linear-gradient(180deg, #4752E2 0%, #656EE2 100%)"
    }
}))

export default function FilesDragAndDrop ({uploadedFile, setUploadedFile}) {
    const classes = useStyles()
    const drop = React.useRef(null)
    const drag = React.useRef(null);

    const [dragging, setDragging] = React.useState(false)

    const onUpload = (files) => {
        if (files[0]) 
            setUploadedFile(files[0])
    }

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { files } = e.dataTransfer;
        if (files && files.length) {
            onUpload(files);
        }
        setDragging(false);
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.target !== drag.current) {
            setDragging(true);
        }
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.target === drag.current) {
            setDragging(false);
        }
    };

    async function handleUpload (event) {
        if (event.target.files[0]) 
            setUploadedFile(event.target.files[0])
    };

    React.useEffect(() => {
        if(drop && drop.current){
            drop.current.addEventListener('dragover', handleDragOver);
            drop.current.addEventListener('drop', handleDrop);
            drop.current.addEventListener('dragenter', handleDragEnter);
            drop.current.addEventListener('dragleave', handleDragLeave);
        }
        return () => {
            if(drop && drop.current){
                drop.current.removeEventListener('dragover', handleDragOver);
                drop.current.removeEventListener('drop', handleDrop);
                drop.current.removeEventListener('dragenter', handleDragEnter);
                drop.current.removeEventListener('dragleave', handleDragLeave);
            }
        };
        // eslint-disable-next-line
    }, [handleDragOver, handleDrop]);

    return (
    <>
    
        {  uploadedFile == null ?
        <div className={classes.region} ref={drop}>
        {
            dragging &&
            <div className={classes.drag} ref={drag}> </div>
        }
                <img src={require("../constants/upload.png")} alt="Logo" />
                <div >
                <Typography align="center" style={{
                    color: "white",
                    fontSize: "18px",
                    fontWeight: "500",
                    lineHeight: "35px",
                    letterSpacing: "0.1px",
                    paddingTop: "10px"
                    }} gutterBottom>
                    Drag and Drop Here <br/> OR
                </Typography>
                </div>
                <div style={{ justifyContent: "center", textAlign: "center" }}>
                <Button className={classes.uploadButton} variant="contained" component="label" startIcon={<PublishIcon></PublishIcon>}>
                    Browse
                <input
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    id="contained-button-file"
                    type="file"
                    hidden
                    onChange={handleUpload}
                    onClick={(event) => { event.target.value = '' }}
                />
                </Button>
                <Typography style={{
                    fontSize: "13px",
                    fontWeight: "400",
                    lineHeight: "16px",
                    letterSpacing: "0.1px",
                    alignItems: "center",
                    color: "white",
                    marginTop: "15px"
                    }}>
                    Supported File Extensions: .xlsx
                </Typography>
                </div>
                
        </div>
        :
        <Grid container justify="center" alignItems="center" style={{height: "430px"}}>
            <Grid item xs={11} md={11} lg={11} style={{backgroundColor: "white", borderRadius: "4px"}}>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", height: "55px"}}>
                <div style={{display: "flex", alignItems: "center"}}>
                <img src={require("../constants/xls.png")} style={{width:"35px", height: "35px", marginLeft: "10px"}} alt="Logo" />
                    <Typography style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "24px",
                        letterSpacing: "0.1px",
                        alignItems: "center",
                        color: "#3C4043",
                        marginLeft: "10px"
                        }}>
                        {uploadedFile.name}
                    </Typography>
                </div>
                <div style={{ marginTop: "3px", marginRight: "25px"}}>
                    <ClearIcon style={{ color: "#202124", fontSize: "20px", strokeWidth: "4", cursor: "pointer"}} onClick={() => {setUploadedFile(null)}}/>
                </div>
            </div>
            </Grid>
        </Grid>
        }
    </>
  );
}

import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import history from '../history';
import Login from "../pages/Login";
import PrivateRoute from "../components/PrivateRoute";
import RiskAnalysis from "../pages/RiskAnalysis";
import Upload from "../pages/Upload";
import DemoRedirect from "../pages/DemoRedirect";
import PatientErrors from "../pages/PatientErrors";
import HelpGuide from "../pages/HelpGuide";

const App = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/demo-redirect">
          <DemoRedirect />
        </Route>
        <PrivateRoute path="/upload" component={Upload} />
        <PrivateRoute path="/risk_analysis" component={RiskAnalysis} />
        <PrivateRoute path="/patient_data_errors" component={PatientErrors} />
        <PrivateRoute path="/help-guide" component={HelpGuide}/>

        <Route path='/'>
          <Login />
        </Route>

      </Switch>
    </Router>

  );
}
export default App;
